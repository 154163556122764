var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bech32-address"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      placement: 'top',
      content: _vm.copySuccess || "Click to copy"
    }),expression:"{\n      placement: 'top',\n      content: copySuccess || `Click to copy`\n    }"},{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.address),expression:"address",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(function () { return _vm.onCopy(); }),expression:"() => onCopy()",arg:"success"}],staticClass:"address"},[_vm._v(" "+_vm._s(_vm._f("formatBech32")(_vm.address,_vm.longForm, 8, 8))+" ")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      placement: 'top',
      content: _vm.ledgerSuccess || "Click to show on Ledger"
    }),expression:"{\n      placement: 'top',\n      content: ledgerSuccess || `Click to show on Ledger`\n    }"}],staticClass:"show-on-ledger"},[(_vm.session && !_vm.session.isMobile && _vm.session.sessionType === 'ledger')?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        placement: 'top',
        content: _vm.ledgerSuccess || "Click to show on Ledger"
      }),expression:"{\n        placement: 'top',\n        content: ledgerSuccess || `Click to show on Ledger`\n      }"}],staticClass:"show-on-ledger",on:{"click":function () {
          _vm.onShowLedger()
          _vm.showAddressOnLedger()
        }}},[_vm._v(" Show on Ledger ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }